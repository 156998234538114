<script lang="ts" setup>
interface Props {
  duration?: 100 | 300;
}

const props = withDefaults(defineProps<Props>(), {
  duration: 300,
});

const durationClass = props.duration === 300 ? 'duration-300' : 'duration-[100ms]';
</script>

<template>
  <transition
    :enterActiveClass="`${durationClass} ease-in transition-opacity`"
    enterFromClass="transform opacity-0"
    enterToClass="opacity-100"
    :leaveActiveClass="`${durationClass} ease-in`"
    leaveFromClass="opacity-100"
    leaveToClass="transform opacity-0"
    mode="out-in"
  >
    <slot />
  </transition>
</template>
